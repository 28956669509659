import { useVideo, WebPlayer } from '@tivio/core-react-dom'
import React from 'react'

import { InfoMessage } from './InfoMessage'
import { SSORefObject } from './SSO'


interface Props {
    videoId: string
    ssoRef: React.RefObject<SSORefObject>
}

export const VideoPlayer = ({ videoId, ssoRef }: Props) => {
    const { data: video, error } = useVideo(videoId)

    if (error) {
        return <InfoMessage text="error loading the video" />
    }

    if (!video) {
        return <InfoMessage text="Loading ..." />
    }

    return (
        <WebPlayer
            id="player1"
            source={((video.price ? video.teaser : video) || video).path}
            canReplay
            onHasAccountButtonClick={ssoRef.current?.openSSOWindow}
        />
    )
}
