import React, { FC } from 'react'
import styled from 'styled-components'


const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Message = styled.h1`
    color: white;
`

interface Props {
    text: string
}

export const InfoMessage: FC<Props> = ({ text }) => {
    return (
        <Container>
            <Message>{text}</Message >
        </Container>
    )
}
