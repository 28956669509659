import { VideoController } from '@tivio/core-js'
import { PlayerEngineInterface } from '@tivio/types'
import React, { PropsWithChildren, useCallback, useState } from 'react'

import { PlayerContext } from './PlayerContext'


export interface VideoContextValue {
    video: {
        controller?: VideoController | null
        engine?: PlayerEngineInterface | null
    }
}

export const PlayerContextProvider = ({ children }: PropsWithChildren) => {
    const [videoContext, setVideoContext] = useState<VideoContextValue>({
        video: {
            controller: null,
            engine: null,
        },
    })

    const setController = useCallback((controller?: VideoController | null) => {
        setVideoContext((prev) => ({
            video: {
                ...(prev ? prev.video : {}),
                controller,
            },
        }))
    }, [setVideoContext])

    const setEngine = useCallback((engine?: PlayerEngineInterface | null) => {
        setVideoContext((prev) => ({
            video: {
                ...(prev ? prev.video : {}),
                engine,
            },
        }))
    }, [setVideoContext])

    return (
        <PlayerContext.Provider value={{ ...videoContext, setController, setEngine }}>
            {children}
        </PlayerContext.Provider>
    )
}
