import {
    isIos,
    isTizen,
    isWebos,
    Logger,
    transparentOnePixel,
} from '@tivio/common'
import { getBrowserInfo, VideoController } from '@tivio/core-js'
import { PlayerContext } from '@tivio/core-react'
import {
    PlayerEngineFactory,
    PlayerEngineInterface,
    PlayerSource,
    SourceParams,
    SourceType,
    VodTivioSourceInterface,
    Watermark,
} from '@tivio/types'
import React, {
    ChangeEvent,
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react'

import { HTMLTivioVideoElement } from '../types'

import { Watermarker } from './Watermarker'


const logger = new Logger('VideoPlayer')

export interface VideoPlayerProps {
    playerId: string
    videoElementHtmlId?: string
    /**
     * Gets called when video unit ended (aka video and all its ads
     * including preroll, midroll and postroll have fully finished playing)
     */
    onEnded?: () => any
    onPause?: () => any
    onPlaybackStarted?: () => any
    onProgress?: (event: ChangeEvent<HTMLVideoElement>) => any
    onVideoElementCreated?: (videoElement: HTMLVideoElement) => any
    onDeviceLimitExceeded?: () => void
    onUnpause?: () => any
    source?: SourceParams | null
    className?: string
    autoplay: boolean
    isMutedByDefault?: boolean
    doNotSaveWatchPosition?: boolean
    createShakaEngine: PlayerEngineFactory,
    createFairplayEngine: PlayerEngineFactory,
}

const getPlayerEngineFactory = ({
    createShakaEngine,
    createFairplayEngine,
}: {
    createShakaEngine: PlayerEngineFactory
    createFairplayEngine: PlayerEngineFactory
}): PlayerEngineFactory => {
    logger.info('Deciding which player engine to use...')

    if (isTizen()) {
        logger.info('Device is a Tizen TV')
        logger.info('Chose Shaka Engine (for Tizen)')

        return (videoElement: HTMLVideoElement) => createShakaEngine(videoElement)
    }

    if (isWebos()) {
        logger.info('Device is a Webos TV')
        logger.info('Chose Shaka Engine (for Webos)')

        return (videoElement: HTMLVideoElement) => createShakaEngine(videoElement)
    }

    logger.info('Deciding which player engine to use based on browser name...')

    const browserName = getBrowserInfo()?.name ?? ''

    logger.info('Browser name is:', browserName)

    const isSocialAppBrowser = ['instagram', 'facebook'].includes(browserName)
    if (browserName.search(/safari|crios|ios/) > -1 || (isIos() && isSocialAppBrowser)) {
        logger.info('Chose Fairplay engine (for Safari, iOS, Crios)')

        return (videoElement: HTMLVideoElement) => createFairplayEngine(videoElement)
    }

    logger.info('Chose Shaka Engine (default engine)')

    return (videoElement: HTMLVideoElement) => createShakaEngine(videoElement)
}

const shouldLoadSource = (source: VideoPlayerProps['source'], currentSource: PlayerSource | null) => {
    if (source?.type === SourceType.VOD_TIVIO) {
        const currentSourcePath = (currentSource as VodTivioSourceInterface | null)?.videoPath
        return !currentSource || source.videoPath !== currentSourcePath
    } else {
        return true
    }
}

export const VideoPlayer = forwardRef<HTMLVideoElement, VideoPlayerProps>((props, ref) => {
    const {
        playerId,
        onEnded,
        onPause,
        onPlaybackStarted,
        onUnpause,
        source,
        onDeviceLimitExceeded,
        className,
        createShakaEngine,
        createFairplayEngine,
        doNotSaveWatchPosition,
        autoplay,
        isMutedByDefault,
        onProgress,
        onVideoElementCreated,
        videoElementHtmlId,
    } = props

    const [watermark, setWatermark] = useState<Watermark | null>(null)
    const { video, setController, setEngine } = useContext(PlayerContext)

    useEffect(() => {
        return function videoPlayerCleanup() {
            video.controller?.destroy()
            video.engine?.destroy()
            setController(undefined)
            setEngine(undefined)
        }
    }, [])

    useEffect(() => {
        return () => {
            video.controller?.close()
        }
    }, [])

    useEffect(() => {
        const listener = () => onEnded?.()

        video.controller?.addEventListener('video_unit_ended', listener)

        return () => {
            video.controller?.removeEventListener('video_unit_ended', listener)
        }
    }, [onEnded, video.controller])

    const createVideoControllerOnce = useCallback(async (videoElement: HTMLVideoElement | null) => {
        if (videoElement && !video.engine && !video.controller) {
            onVideoElementCreated?.(videoElement)

            const engineFactory = getPlayerEngineFactory({ createShakaEngine, createFairplayEngine })
            const engine: PlayerEngineInterface = await engineFactory(videoElement)

            const videoController = new VideoController(
                engine,
                playerId,
                doNotSaveWatchPosition,
            )

            // TODO TIV-1296 these listeners get set only 1x !!! what if somebody changes
            // the onPause prop etc? it will stop working!

            // TODO TIV-1296 does it make sense to use these listeners or better to use video.controller
            // from context and add listeners to it? I think second option
            videoController.addEventListener('videostarted', () => onPlaybackStarted?.())
            videoController.addEventListener('pause', () => onPause?.())
            videoController.addEventListener('play', () => onUnpause?.())
            videoController.addEventListener('watermark', setWatermark)
            videoController.addEventListener('device_limit_exceed', () => onDeviceLimitExceeded?.())

            if (ref) {
                if (typeof ref === 'function') {
                    ref(videoElement)
                } else {
                    (videoElement as HTMLTivioVideoElement).tivio = { controller: videoController }
                    ref.current = videoElement
                }
            }

            setController(videoController)
            setEngine(engine)
        }
    }, [])

    useEffect(() => {
        console.log('debug:videoController', video.controller)

        if (source && video.controller) {
            const logId = (() => {
                switch (source.type) {
                    case SourceType.VOD_TIVIO: return source.videoPath
                    case SourceType.VOD_EXTERNAL: return source.url
                    case SourceType.CHANNEL: return source.url
                    default: return undefined
                }
            })()

            if (!video.controller) {
                logger.error('Trying to load or play, but video controller is not yet initialized')
            } else if (shouldLoadSource(source, video.controller.currentSource)) {
                // Source is loaded for the first time
                logger.info(`video controller load source: ${source.type}, id: ${logId}, autoplay: ${props.autoplay}`)
                video.controller?.load(source, props.autoplay)
            } else {
                // Source url is changed but source (video) is the same - play right away after new source is accepted
                // TODO when player is paused, changing source makes it unpaused
                logger.info(`video controller play source: ${source.type}, id: ${logId}, autoplay: ${props.autoplay}`)
                video.controller?.changeSource(source)
            }

            onUnpause?.()
        }
    }, [source, video])


    return (
        <Watermarker
            watermark={watermark}
            onWatermarkElementChange={video.controller?.onWatermarkElementChange}
        >
            <video
                id={videoElementHtmlId}
                className={className}
                autoPlay={autoplay}
                controls={false}
                onProgress={onProgress}
                ref={createVideoControllerOnce}
                muted={isMutedByDefault}
                poster={transparentOnePixel}
                playsInline={true}
                controlsList="nodownload"
            />
        </Watermarker>
    )
})
