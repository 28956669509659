import { RouterOverridesContextProvider as CoreRouterOverridesContextProvider } from '@tivio/core-react-dom'
import React, { FC, PropsWithChildren, ReactNode } from 'react'
import styled from 'styled-components'


const LinkWrapper = styled.a`
    text-decoration: none;
`

const voidFn = () => {}

// TODO: make those overrides optional
export const RouterOverridesContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const state = {
        routerOverrides: {
            getVideoDetailPath: () => '',
            getSeriesDetailPath: () => '',
            getTagPath: () => '',
            getLoginPath: () => '',
            getRegistrationPath: () => '',
            getLivePlayerPath: () => '',
            getVodPlayerPath: () => '',
            getHomePath: () => '',
            getArticlePath: () => '',
            getApplicationPath: () => '',
            getGridPath: () => '',

            goToVideoDetailPage: voidFn,
            goToSeriesDetailPage: voidFn,
            goToTagPage: voidFn,
            goBack: voidFn,
            goVodPlayer: (videoId: string) => {
                window.location.href = `/?videoId=${videoId}`
            },
            goLivePlayer: voidFn,
            goToHome: voidFn,
            goToLoginPage: () => {

            },
            goToRegistrationPage:voidFn,
            goToRoute: voidFn,
            goToArticle: voidFn,
            goToRecommendedFromOrganization: voidFn,
            goToRecommendedFromOrganizationRow: voidFn,
            goToMagentaMomentsPage: voidFn,
            goToVoucherRedeemPage: voidFn,
            updateParams: voidFn,
        },
        // eslint-disable-next-line react/react-in-jsx-scope
        Link: ({ href, target, children }: { href?: string; target?: string; children?: ReactNode }) => (
            <LinkWrapper
                target={target}
                href={href}
            >
                {children}
                
            </LinkWrapper>
        ),
    }

    return (
        // @ts-expect-error
        <CoreRouterOverridesContextProvider state={state}>
            <div>
                {children}
            </div>
        </CoreRouterOverridesContextProvider>
    )
}
