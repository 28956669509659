import { useGenerateInviteCode } from '@tivio/core-react-dom'
import { useEffect } from 'react'


interface Props {
    onCodeGenerated: (code: string) => void
}

export const GenerateCode = ({ onCodeGenerated }: Props) => {

    const { inviteCode } = useGenerateInviteCode()

    useEffect(() => {
        if (!inviteCode?.code) {
            return
        }

        onCodeGenerated(inviteCode.code)
    }, [inviteCode, onCodeGenerated])

    return null
}
