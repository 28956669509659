import { createContext } from 'react'

import type { VideoController } from '@tivio/core-js'
import type { PlayerEngineInterface } from '@tivio/types'


interface PlayerContextValue {
    video: {
        controller?: VideoController | null
        engine?: PlayerEngineInterface | null
    },
    setEngine: (engine?: PlayerEngineInterface | null) => void
    setController: (controller?: VideoController | null) => void
}

export const PlayerContext = createContext<PlayerContextValue>({
    video: {
        controller: null,
        engine: null,
    },
    setController: () => {
        console.error('no PlayerContextProvider used')
    },
    setEngine: () => {
        console.error('no PlayerContextProvider used')
    },
})
