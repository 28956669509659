import { tivio } from '@tivio/core-js'
import { useEffect, useState } from 'react'

import { useError } from './useError'

import type { Article, PaginationInterface, SubscribeToSeriesEpisodesOptions, Video } from '@tivio/types'


/**
 * Returns videos with given tag ids
 * @param tagIds - tag ids
 * @param seriesRef - ref to Series Document
 * @param organizationPath - path to Organization Document
 * @param options - subscription options
 */
export const useSubscribeToSeriesEpisodesWithArticles = (
    seriesTag: string,
    seriesRef: any,
    organizationPath: string,
    options: SubscribeToSeriesEpisodesOptions = {},
) => {
    const [pagination, setPagination] = useState<PaginationInterface<Video | Article> | null>(null)
    const { error, raiseError } = useError()

    useEffect(() => {
        if (!seriesRef) {
            return
        }
        const disposer = tivio.subscribeToSeriesEpisodesWithArticles(
            // TODO - resolve tags count another way
            seriesRef,
            seriesTag,
            organizationPath,
            (error, data) => {
                if (error) {
                    raiseError(error)
                }

                if (data) {
                    setPagination({
                        items: data.items,
                        fetchMore: data.fetchMore,
                        hasNextPage: data.hasNextPage,
                        loading: data.loading,
                    })
                }
            },
            options,
        )

        return () => {
            disposer?.()
        }
    }, [options, options.orderBy, organizationPath, raiseError, seriesRef, seriesTag])


    return {
        pagination,
        error,
    }
}