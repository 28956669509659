import { app, Row } from '@tivio/core-js'
import { BannerItemComponent } from '@tivio/types'
import { useCallback, useEffect, useState } from 'react'

import { itemIsArticle, itemIsTag, itemIsVideo } from '../../utils'

import type { Article, Tag, Video } from '@tivio/types'


interface BackroundBanner {
    row: Row;
    backgroundMobile?: string;
    background?: string;
}

interface Section {
    sectionId: string;
    organizationId: string | null;
    rows: Row[];
    expanded: boolean;
}

export const useGroupedRows = (rows: Row[], isHomePage?: boolean) => {
    const [groupedRows, setGroupedRows] = useState<Section[]>([])
    const [expandedSectionStates, setExpandedSectionStates] = useState<Record<string, boolean>>({})
    const [backgroundBanner, setBackgroundBanner] = useState<BackroundBanner>()

    useEffect(() => {
        const sections: Section[] = []
        const newExpandedSections: Record<string, boolean> = {}

        rows.forEach((row, index) => {
            const organizationId = row?.organizationId ?? null
            const sectionId = `section-${index}`

            if (index === 0) {
                const firstTile = row.tiles.items[0]
                if (isHomePage && row.itemComponent === BannerItemComponent.ORGANIZATION_BANNER) {
                    setBackgroundBanner({ background: app.organization?.application?.organization?.organizationBanner ?? '', row })
                    return
                }

                const isFirstTileVideoOrTag = itemIsVideo(firstTile) || itemIsTag(firstTile) || itemIsArticle(firstTile)
                const isFirstRowBackgroundBanner =
                    row.itemComponent === BannerItemComponent.BACKGROUND_BLUR ||
                    row.itemComponent === BannerItemComponent.BACKGROUND ||
                    (row.itemComponent && row.itemComponent === BannerItemComponent.ORGANIZATION_BANNER)

                if (isFirstRowBackgroundBanner && isFirstTileVideoOrTag) {
                    setBackgroundBanner(getBanner(firstTile, row))
                    return
                }

                setBackgroundBanner(undefined)
            }

            // Determine if a new section starts
            if (!sections.length || sections[sections.length - 1].organizationId !== organizationId) {
                sections.push({ sectionId, organizationId, rows: [row], expanded: false })
                newExpandedSections[sectionId] = false
            } else {
                sections[sections.length - 1].rows.push(row)
            }
        })

        setGroupedRows(sections)
        setExpandedSectionStates(newExpandedSections)
    }, [rows, isHomePage])

    const toggleSection = useCallback((sectionId: string) => {
        setExpandedSectionStates((prevState) => ({
            ...prevState,
            [sectionId]: !prevState[sectionId],
        }))
    }, [])

    return { groupedRows, expandedSectionStates, toggleSection, backgroundBanner }
}


const getBanner = (firstTile: Video | Article | Tag, row: Row) => {
    if (itemIsVideo(firstTile)) {
        return {
            backgroundMobile: firstTile?.backgroundBlurBannerMobile || undefined,
            background: firstTile.detailBanner,
            row,
        }
    } else {
        return {
            backgroundMobile: firstTile.bannerMobile || undefined,
            background: firstTile.detailBanner,
            row,
        }
    }
}