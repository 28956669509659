import { tivio, useUser } from '@tivio/core-react-dom'
import React, {
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react'

import { GenerateCode } from './GenerateCode'


export interface SSORefObject {
    openSSOWindow?: () => void
}

export const SSO = React.forwardRef<SSORefObject>((_, ref) => {
    const isProduction = process.env.NODE_ENV === 'production'

    const hasOpenedSSOWindowRef = useRef(false)
    const [code, setCode] = useState<string>()
    const { user } = useUser()

    const hasValidPurchaseFromOrg = useMemo(() => {
        if (!user?.isSignedIn) {
            return false
        }

        return user.purchases.some(purchase => tivio.organization?.monetizations.some(monetization => monetization.id === purchase.monetizationId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, tivio.organization, tivio.organization?.monetizations])

    useEffect(() => {
        if (hasValidPurchaseFromOrg) {
            setCode(undefined)
        }
    }, [hasValidPurchaseFromOrg])            

    useImperativeHandle(ref, () => ({
        openSSOWindow: () => {
            if (!hasValidPurchaseFromOrg && code) {
                const baseUrl = isProduction ? `https://${tivio.organization?.hostname ?? 'stargaze.com'}` : 'http://localhost:3000'
                const url = `${baseUrl}/sso?code=${code}`
                window.open(url, '_blank')
                hasOpenedSSOWindowRef.current = true
            }
        },
    }))

    if (!hasValidPurchaseFromOrg) {
        return <GenerateCode onCodeGenerated={setCode} />
    }

    return null
})

SSO.displayName = 'SSO'
