import { tivio, tivioFirebaseAuth, TivioProvider, UserContextProvider } from '@tivio/core-react-dom'
import { Currency, LangCode, TivioConfig } from '@tivio/types'
import queryString from 'query-string'
import React, { createRef, useEffect, useState } from 'react'

import { InfoMessage } from './InfoMessage'
import { RouterOverridesContextProvider } from './RouterOverridesContextProvider'
import { SSO, SSORefObject } from './SSO'
import { VideoPlayer } from './VideoPlayer'


const QUERY_PARAMS = queryString.parse(window.location.search)

declare global {
    interface Window {
        IS_TIVIO_EMBED: boolean
    }
}

function App() {
    const { videoId } = QUERY_PARAMS
    const ssoRef = createRef<SSORefObject>()
    const [initialized, setInitialized] = useState(false)

    useEffect(() => {
        window.IS_TIVIO_EMBED = true

        async function initTivio() {
            if (!videoId) {
                return
            }

            const config = {
                // TODO: set from organization document, not all have field currencies or overrideCurrency set to anything
                currency: 'CZK' satisfies Currency,
                verbose: true,
                debug: true,
                // TODO: Language configuration from organization
                language: LangCode.CS,
                // TODO: Where are ads supposed to be from?
                player: { adService: { name: 'IMA SDK' } },
            } satisfies Omit<TivioConfig, 'secret'>

            await tivio.createFirebase(config)

            const initData = await tivio.getOrganizationInfoFromContentId(videoId as string, 'videos')

            await tivio.init({ ...config, ...initData })

            if (tivio.organizationId && (!tivio.user?.isSignedIn || tivio.user.organizationRef?.id !== tivio.organization?.application?.organizationId)) {
                try {
                    const token = await tivioFirebaseAuth.signUpAnonymously(tivio.organizationId)
                    await tivioFirebaseAuth.signInWithCustomToken(token)
                } catch (error) {
                    console.error('caught error logging in anonymous user', error)
                }
            }

            setInitialized(true)
        }

        initTivio()

        return () => tivio.destroy()
    }, [videoId])

    if (!videoId || typeof videoId !== 'string') {
        return <InfoMessage text="videoId query param is missing." />
    }

    if (!initialized) {
        return <InfoMessage text="Loading ..." />
    }

    return (
        <UserContextProvider>
            <TivioProvider language={LangCode.CS}>
                <RouterOverridesContextProvider>
                    <SSO ref={ssoRef} />
                    <VideoPlayer
                        videoId={videoId}
                        ssoRef={ssoRef}
                    />
                </RouterOverridesContextProvider>
            </TivioProvider>
        </UserContextProvider>
    )
}

export default App
